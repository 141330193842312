<script>
	import RunningFaucets from "./RunningFaucets.svelte";
	import FPSSpot from "./FpsSpot.svelte";
	import anime from "animejs/lib/anime.es";
	import {onMount} from 'svelte';

	onMount(async () => {
		setTimeout(playAnimationWave, 1000);
		setTimeout(playAnimationPopup, 3000);
	});

	function playAnimationWave() {
		anime({
			targets: '.animate-wave',
			rotate: 180,
			duration: 300,
			easing: 'easeInOutSine',
			direction: 'alternate',
			loop: 1,
		});
	}

	function playAnimationPopup() {
		anime({
			targets: '.animate-popup',
			opacity: 1,
			scaleX: [0, 1],
			duration: 1000,
			direction: 'alternate',
			loop: 1
		});
	}
	
</script>

<svelte:head>
	<link rel="preconnect" href="https://fonts.googleapis.com">
	<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
	<link href="https://fonts.googleapis.com/css2?family=Fredoka&display=swap" rel="stylesheet"> 
</svelte:head>

<main>

	<div class="animate-wave" on:click={playAnimationWave} > 👋 </div>
	<div class="hero">
		<p>Hi! My name is</p>
		<h1 on:focus={playAnimationPopup} on:click={playAnimationPopup} on:mouseover={playAnimationPopup} class="headline">Andrew Carneal</h1>
		<div class="animate-popup"> <p>But you can call me Andy 😉</p></div>
		<p>I'm a web developer in Nashville, TN. </p> <p>My favorite things to use are <b>React</b>, <b>Svelte</b>, and <b>Node.js</b>.</p>
	</div>
	
	<section class="contact-me">
		<h2>Get in touch</h2>

		<div class="tiles">
			<div class="icon-tile">
				<a href="https://www.linkedin.com/in/andrewcarneal/"><img src="/images/linkedin.svg" alt="LinkedIn Logo"/></a>
				<p>LinkedIn</p>
			</div>
			
			<div class="icon-tile">
				<a href="mailto:andrewlcarneal@gmail.com">
					<img src="/images/email.svg" alt="Email Envelope Icon">
					
				</a>
				<p>Email Me</p>
			</div>
		</div>
		
		
	</section>
	
	<section class="component-container">
		<h2>Check out what I've built</h2>
		<RunningFaucets />		
		<FPSSpot/>
	</section>
</main>


<footer></footer>

<style>
	main {
		font-family: 'Fredoka', sans-serif;
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 5rem auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	h1 {
		color: #444;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	.animate-wave {
		font-size: 2rem;
		width: 3rem;
	}
	.animate-wave:hover{
		cursor: pointer;
	}

	.animate-popup{
		width: 300px;
		height: 4rem;
		color: #fff;
		opacity: 0;
		background-color: rgba(0,0,0,0.7);
		position: absolute;
		top: 180px;
		clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 65% 75%, 50% 100%, 35% 75%, 0% 75%);
	}

	.animate-popup p {
		margin: 13px;
	}

	.headline:hover{
		cursor: pointer;
		color: #999;
		transition: 200ms;
	}

	.hero{
		margin-bottom: 2.5rem;
	}

	.component-container{
		margin-top: 5rem;
	}

	.component-container h2{
		margin-bottom: 2.5rem;
	}

	.tiles{
		display: flex;
		align-items: baseline;
	}

	.icon-tile{
		width: 100px;
	}

	.icon-tile p{
		margin-top: 0;
	}

	.icon-tile img{
		width: 64px;
		filter: invert(22%) sepia(0%) saturate(67%) hue-rotate(198deg) brightness(94%) contrast(80%);
		transition: 200ms;
	}

	.icon-tile img:hover{
		filter: invert(64%) sepia(2%) saturate(0%) hue-rotate(36deg) brightness(95%) contrast(96%);
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
		
	}

	@media(max-width: 640px){
		.animate-popup{
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}
</style>