<script>
    import anime from "animejs/lib/anime.es";

    function playAnimationCardEnter(){
		anime({
			targets: '.runningfaucets',
			scale: [1, 1.1],
			duration: 500,

		})
	}
	function playAnimationCardExit(){
		anime({
			targets: '.runningfaucets',
			scale: [1.1, 1],
			duration: 600,
		})
	}
</script>

<div class="component runningfaucets" on:click={() => window.open("https://runningfaucets.com/", "_blank")} on:focus={playAnimationCardEnter} on:mouseenter={playAnimationCardEnter} on:mouseleave={playAnimationCardExit}>
    <h3>RunningFaucets.com</h3>
    <img src="/images/runningfaucets.png" alt="">
    <div class="description">
        <p>I created RunningFaucets.com after finding it increasingly difficult to find an active faucet for Ethereum testnet ETH.</p>
    </div>
    
    <div class="tech-stack">
        <h4>Tech Used</h4>
        <ul>
            <li>Svelte</li>
            <li>Node.js</li>
        </ul>
    </div>
    
    
</div>

<style>
    .component{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 70em;
        border-radius: 0.75rem;
        -webkit-box-shadow: 5px 5px 15px 5px #adadad; 
        box-shadow: 5px 5px 15px 5px #adadad;
        padding: 1rem;
        margin-bottom: 2.5rem;
    }

    .component:hover{
        -webkit-box-shadow: 5px 5px 15px 3px #dadada; 
        box-shadow: 5px 5px 15px 3px #dadada;
        cursor: pointer;
        /* transform: scale(1.05); */
    }

    .component img {
        width: 250px;
        border: 1px solid #adadad;
    }

    .description{
        max-width: 500px;
    }

    .tech-stack{
        text-align: left;
        
    }

    .tech-stack ul {
        padding: 0;
    }
</style>